<template>
    <Modal :title="modalTitle" v-model="isShowModal">
        <template v-slot:modal-title>
            <div
                v-if="stepSeq === 1"
                class="hint"
                v-text="'請點擊一個您要搜尋的欄位'"
            />
            <div
                v-if="stepSeq === 2"
                class="hint"
                v-text="`搜尋欄位: ${$getColumnLabel(columnKey)}`"
            />
        </template>
        <template v-slot:modal-content>
            <div v-if="stepSeq === 1" class="step-one-wrapper">
                <div class="choose-column-wrapper">
                    <div
                        v-for="column in columns"
                        :key="column.key"
                        class="choose-column-container"
                        @click="setColumnKey(column.key)"
                    >
                        <span v-text="$getColumnLabel(column.key)" />
                        <span
                            class="dark-filter-icon"
                            :class="{
                                'red-dot': hasSelected(column.key)
                            }"
                        />
                    </div>
                </div>
            </div>
            <div v-if="stepSeq === 2">
                <CheckboxDropdown
                    title="快速搜尋條件"
                    name="filter"
                    placeholder="請輸入搜尋條件關鍵字"
                    :filterType="filterType"
                    :options="dropdownFilterOptions"
                    v-model="checkedListValue[columnKey]"
                />
            </div>
        </template>
        <template v-slot:modal-bottom>
            <div v-if="stepSeq === 1" class="button-wrapper">
                <div class="button-container">
                    <Button
                        buttonWord="清除全部查詢條件"
                        buttonStyle="grey"
                        @click.prevent="clear()"
                    />
                </div>
                <div class="button-container">
                    <Button
                        buttonWord="套用並關閉"
                        buttonStyle="blue"
                        @click.prevent="apply()"
                    />
                </div>
            </div>
            <div class="modal-bottom-container" v-if="stepSeq === 2">
                <div
                    class="chosen-wording"
                    v-text="
                        `共${dropdownFilterOptions.length}個條件，已選${checkedCount}個條件`
                    "
                />
                <div class="button-wrapper">
                    <div class="button-container">
                        <Button
                            buttonWord="清除查詢條件"
                            buttonStyle="grey"
                            @click.prevent="
                                () => {
                                    setEachOptionUnchecked(columnKey)
                                }
                            "
                        />
                        <Button
                            buttonWord="搜尋其他欄位"
                            buttonStyle="grey"
                            @click.prevent="
                                () => {
                                    stepSeq = 1
                                }
                            "
                        />
                    </div>
                    <div class="button-container">
                        <Button
                            buttonWord="套用並關閉"
                            buttonStyle="blue"
                            @click.prevent="apply()"
                            class="apply"
                        />
                    </div>
                </div>
            </div>
        </template>
    </Modal>
</template>

<script>
import _ from 'lodash'
import Modal from '@/components/Modal.vue'
import Button from '@/components/Button.vue'
import CheckboxDropdown from '@/components/CheckboxDropdown.vue'

export default {
    name: 'TableFilterSortModal',
    emits: [
        'update:modelValue',
        'update:checkedList',
        'update:filterColumnKey'
    ],
    components: {
        Modal,
        Button,
        CheckboxDropdown
    },
    props: {
        modelValue: {
            type: Boolean,
            default: false
        },
        columns: {
            type: Array,
            default: function () {
                return []
            }
        },
        rows: {
            type: Array,
            default: function () {
                return []
            }
        },
        isFilterSetList: {
            type: Object,
            default: function () {
                return {}
            }
        },
        filterColumnKey: {
            type: String,
            default: ''
        },
        checkedList: {
            type: Object,
            default: function () {
                return {}
            }
        }
    },
    methods: {
        clear: function () {
            this.setAllOptionUnchecked()
            this.$setGaEvent(`clearFilter`, 'filter-Tool')
        },
        reset: function () {
            this.checkedListValue = _.cloneDeep(this.checkedList)
        },
        apply: function () {
            this.$emit('update:checkedList', this.checkedListValue)

            if (this.columnKey) {
                this.$setGaEvent(`${this.columnKey}Filter`, 'filter-Column')
            }
            this.isShowModal = false
            this.columnKey = ''
        },
        setColumnKey: function (columnKey) {
            this.columnKey = this.$getColumnKey(columnKey)
            this.stepSeq = 2
        },
        getOptions: function (row) {
            let deepRows = []
            let option = {}
            if (row._detail && row._detail.length > 0) {
                deepRows = row._detail.reduce((memo, deepRow) => {
                    memo.push(...this.getOptions(deepRow))
                    return memo
                }, [])
            }
            /* *** Value處理  *** */
            // 資料通常有兩種格式：(1)值 (2)object包html、position
            if (
                row[this.columnKey] &&
                typeof row[this.columnKey] === 'object'
            ) {
                option = {
                    label: this.getOptionLabel(row[this.columnKey].value),
                    value: row[this.columnKey].value?.toString() || ''
                }
            } else {
                option = {
                    label: this.getOptionLabel(row[this.columnKey]),
                    value: row[this.columnKey]?.toString() || ''
                }
            }

            return [...deepRows, option]
        },
        getOptionLabel: function (value) {
            if (typeof value === 'number') return this.$numberWithComma(value)
            if (value === null || value === undefined) return '-'
            if (value === '') return '(空白)'
            return value.replace(/&nbsp;/g, ' ')
        },
        setAllOptionUnchecked: function () {
            const columnKeys = Object.keys(this.checkedListValue)
            columnKeys.forEach((columnKey) => {
                this.setEachOptionUnchecked(columnKey)
            })
        },
        setEachOptionUnchecked: function (columnKey) {
            const list = Object.keys(this.checkedListValue[columnKey])
            list.forEach((value) => {
                this.checkedListValue[columnKey][value] = false
            })
        },
        setResetGa: function () {
            this.$setGaEvent(`resetFilter`, 'filter-Tool')
        },
        getAliasKey: function (col) {
            if (col?.alias) return `${col.key}Trans`
            else return col.key
        },
        hasSelected: function (columnKey) {
            return Object.values(
                this.checkedListValue[columnKey] ??
                    this.checkedListValue[`${columnKey}Trans`] ??
                    {}
            ).includes(true)
        },
        scrollToTop: function () {
            this.$nextTick(() => {
                const modal = this.$el.querySelector('.modal-area')
                const chooseColumnWrapper =
                    this.$el.querySelector('.choose-column-wrapper') ?? ''
                modal.scrollTo({
                    top: 0
                })
                if (chooseColumnWrapper)
                    chooseColumnWrapper.scrollTo({
                        top: 0
                    })
            })
        }
    },
    computed: {
        isShowModal: {
            get() {
                return this.modelValue
            },
            set(val) {
                this.$emit('update:modelValue', val)
            }
        },
        columnKey: {
            get() {
                return this.filterColumnKey
            },
            set(val) {
                this.$emit('update:filterColumnKey', val)
            }
        },
        modalTitle: function () {
            return this.stepSeq === 1
                ? '快速搜尋 - 1.搜尋欄位選擇'
                : '快速搜尋 - 2.選定搜尋條件'
        },
        dropdownFilterOptions: function () {
            return _.uniqBy(
                this.rows
                    .reduce((memo, row) => {
                        memo.push(...this.getOptions(row))
                        return memo
                    }, [])
                    .sort((cellA, cellB) => {
                        if (this.filterType === 'number') {
                            const numberA = this.$getPureNumber(cellA.value)
                            const numberB = this.$getPureNumber(cellB.value)
                            return numberA - numberB
                        } else if (this.filterType === 'date')
                            return cellB.value.localeCompare(cellA.value)
                        else return cellA.value.localeCompare(cellB.value)
                    }),
                (option) => option.value
            )
        },
        filterType: function () {
            return this.$getColumnSetting(this.columnKey)?.type || 'string'
        },
        checkedCount: function () {
            const checkedList = this.checkedListValue[this.columnKey] ?? []
            return Object.values(checkedList).filter((value) => value).length
        }
    },
    watch: {
        checkedList: {
            handler() {
                this.checkedListValue = _.cloneDeep(this.checkedList)
            },
            immediate: true
        },
        isShowModal: function () {
            if (this.isShowModal) this.scrollToTop()
            setTimeout(() => {
                if (!this.isShowModal) this.columnKey = ''
                this.reset()
            }, 500)
        },
        columnKey: function () {
            this.stepSeq = this.columnKey ? 2 : 1
        },
        stepSeq: function () {
            this.scrollToTop()
        }
    },
    data() {
        return {
            stepSeq: 1,
            checkedListValue: {},
            isApply: false
        }
    }
}
</script>

<style lang="scss" scoped>
.modal {
    :deep(.modal-content) {
        .input-container {
            width: 100%;
        }
    }
}

.hint {
    color: $sixth-black;
    font-size: 15px;
    font-weight: 500;
    margin-top: 20px;
}

.choose-column-wrapper {
    height: Max(280px, 48vh);
    overflow: auto;
    border: 1px solid $ninth-grey;
}

.choose-column-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 10px 20px;
    color: $secondary-grey;
    font-size: 14px;
    cursor: pointer;

    &:hover {
        background-color: $primary-grey;
    }
}

.dark-filter-icon {
    display: block;
    height: 15px;
    width: 15px;
    &.red-dot:after {
        content: ' ';
        display: block;
        margin: -4px 0 0 16px;
        width: 5px;
        height: 5px;
        border-radius: 50%;
        background-color: $primary-red;
    }
}

.modal-bottom-container {
    width: 100%;
}

.button-wrapper {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    width: 100%;
}

.button-container {
    display: flex;
    justify-content: flex-end;

    @media screen and (max-width: 576px) {
        width: 100%;
        justify-content: center;

        &:not(:last-of-type) {
            margin-bottom: 10px;
        }
        .button {
            width: 100%;
        }
    }
}

.chosen-wording {
    width: 100%;
    color: $sixth-black;
    font-size: 14px;
    text-align: left;
    margin-bottom: 10px;
}

:deep(.input-container) {
    margin-bottom: 10px;
}
</style>
